import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDWHakdlUcSqeKiNl0glEGIlvrfq7tniA4",
    authDomain: "la-la-laundry.firebaseapp.com",
    databaseURL: "https://la-la-laundry.firebaseio.com",
    projectId: "la-la-laundry",
    storageBucket: "la-la-laundry.appspot.com",
    messagingSenderId: "525974908052",
    appId: "1:525974908052:web:74c59ba6517306c3309881",
    measurementId: "G-429R9LSKY0",
};

export const firebaseApp = initializeApp(firebaseConfig);
