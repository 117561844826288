import React, { Component } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { firebaseApp } from "./firebase-config.js";

class FirebaseListenerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
        };

        this.firebaseRef = ref(getDatabase(firebaseApp), "sounds/new_order");
    }

    componentDidMount() {
        // Set up Firebase listener
        onValue(this.firebaseRef, (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();

                if (data.placed) {
                    this.playSound(data.total);
                }
            }
        });
    }

    playSound(total) {
        let song = new Audio(
            "./ringtones/mixkit-happy-bells-notification-937.mp3"
        );
        song.play();

        setTimeout(() => {
            set(this.firebaseRef, {
                placed: false,
                total: total || 0,
            });
        }, 3000);
    }

    componentWillUnmount() {
        // Remove Firebase listener to prevent memory leaks
        this.firebaseRef.off();
    }

    render() {
        const { data } = this.state;

        return <></>;
    }
}

export default FirebaseListenerComponent;
